.category-grid {
    display: inline-block;
    padding: 10px;
    transition: 0.5s ease;
}

.category-grid button {
    font-family: Quicksand;
    background-color: #00000000;
    border-top: #00000000;
    border-right: #00000000;
    border-left: #00000000;
    border-bottom: 4px solid #FFC227;
    border-radius: 3px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 35px;
    width: auto;
    height: 50px;
    margin: 0px 14px;
    cursor: pointer;

}
.text-container{
    font-family: Quicksand;
}

.category-grid button:hover {
    background-color: #FFC227;
    color: black;
}

.category-grid button:active {
    background-color: #FFC227;
    color: black;
}

.category-grid button:focus {
    border-bottom: 10px solid #FFC227;
    font-weight: 600;
}

.grid-container {
    transition: 0.5s ease;
  display: grid;
  grid-template-columns: 1fr auto 5fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 10px;
  padding: 2px;
  padding-left: 10px;
  grid-template-areas: 
    "selector picture name price"
    "stock picture name price";
}

.grid-container button {
    background-color: #00000000;
    border: 1px solid #FFC227;
    border-radius: 3px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    width: 30px;
    height: 30px;
    margin: 0px 14px;
    cursor: pointer;
    padding: 0px 0px 20px 0px;
}

.grid-container button:hover {
    background-color: #FFC227;
    color: black;
}

.grid-item-1 {
    margin-top: 10px;
    text-align: center;
    font-size: 30px;
    grid-area: selector;
}
.grid-item-2 {
    text-align: center;
    grid-area: picture;
}
.grid-item-3 {
    scale: 1.0;
    margin-top: 42px;
    text-align: center;
    font-size: 45px;
    grid-area: name;
}
.grid-item-4 {
    text-align: center;
    font-size: 16px;
    grid-area: description;
}
.grid-item-5 {
    text-align: center;
    align-items: center;
    font-size: 30px;
    grid-area: price;
}
.grid-item-6 {
    text-align: center;
    font-size: 25px;
    grid-area: stock;
}

.grid2-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(25px, auto);
  padding: 4px;
}

.grid2-item-7 {
    margin-top: 10px;
}
.grid2-item-7 button {
    background-color: #00000000;
    border: 1px solid #FFC227;
    border-radius: 3px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    padding: 4px;
}

.grid2-item-7 button:hover {
    background-color: #FFC227;
    color: black;
}

.grid2-item-8 {
    text-align: center;
    grid-column-start: 3;
    grid-column-end: -1;
}
@media only screen and (max-width: 1300px) {

    .category-grid {
        transform: scale(0.9);
    }
    .grid-container{
        transform: scale(0.9);
    }
    .grid2-item-3 {
        transform: scale(0.9);
    }
}
@media only screen and (max-width: 1200px) {

    .category-grid {
        transform: scale(0.8);
    }
    .grid-container{
        margin-left: 0;
        transform: scale(0.8);
    }
}
@media only screen and (max-width: 1050px) {

    .category-grid {
        transform: scale(0.8);
    }
    .grid-container{
        margin-left: 0;
        transform: scale(0.8);
    }
    .grid-item-3 {
        font-size: 4vw;
    }
    .text-container{
        padding: 0;
    }
}
@media only screen and (max-width: 600px) {

    .category-grid {
        transform: scale(0.75);
        margin-left: 0;
    }
    .grid-container{
        transform: scale(0.75);
        padding-left: 0;
    }
    .text-container{
        padding: 0;
    }
    @media only screen and (max-width: 530px) {

        .category-grid {
            transform: scale(0.6);
            margin-left: 0;
        }
        .grid-container{
            transform: scale(0.6);
            padding-left: 0;
            margin-left: -10%;
        }
        .text-container{
            padding: 0;
        }
}
@media only screen and (max-width: 450px) {

    .category-grid {
        transform: scale(0.55);
        margin-left: -5%;
    }
    .grid-container{
        transform: scale(0.55);
        padding-left: 0;
        margin-left: -15%;
    }
    .text-container{
        padding: 0;
    }
}
}

