@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');


.background {
    background: linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url('img/bg.jpeg') center/cover no-repeat;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: sticky;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

.text-container{
    text-align:center;
    width: 90%;
    margin: auto;
    margin-bottom: 2%;
    height: 50%;
    color: white;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: content-box;
    background-color: rgba(32, 28, 36, 0.5);
    border-radius: 20px;
}
.textblock{
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
}

.slogan{
    margin-top: 2%;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
}

.subquestion{
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
}

.contactinfo{
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    margin-bottom: 2%;
}

.dj-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 50%;
    width: 96%;
    place-items: center;
    background-color: rgba(32, 28, 36, 0.5);
    border-radius: 20px;
    margin: 2%;
    position: relative;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
}
.text-container-grid{
    padding: 10%;
    text-align:center;
    width: 85%;
    margin-bottom: 2%;
    height: 100%;
    color: white;
    position: relative;
    justify-content: space-evenly;
}
.dj-text-container-grid{
    padding: 10%;
    text-align:center;
    width: 85%;
    margin-top: 50%;
    margin-bottom: 2%;
    height: 100%;
    color: white;
    position: relative;
    justify-content: space-evenly;
}

.image-container {
    display: flex;
    position: relative;
    scale: 0.8;
  }
.image-container img {
    border-radius: 35px;
  }
  .image-container {
    display: flex;
    position: relative;
    scale: 0.8;
  }

@media only screen and (max-width: 1300px){
    .dj-text-container-grid{
        margin-top: 0%;
    }
    .dj-grid{
        display: block;
    }
    .image-container {
        justify-content: center;
      }
}

@media only screen and (max-width: 700px){
    .dj-grid{
        display: block;
    }
    .image-container{
        display: none;
    }
}