@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.topspace{
    margin-top: 10em;
    transition: margin-top 0.5s ease-in-out;
}
.footer {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #201C24;
    position: absolute;
    width: 100%;
    padding: 0.5em;
    bottom: 0;
    margin-top: auto;
    font-family: Quicksand, sans-serif; 
    color: #F2F3F5;
    font-size:20px;
    box-sizing: border-box;
    transition: transform 0.5s ease-in-out;
}

.logo {
    margin-left: 0.5em;
    margin-right: 1em;
    width: 4em;
    height: 4em;
    transition: transform 0.5s ease-in-out;
}

.footer p {
    margin: 0;
    margin-left: 0.1em; 
    white-space: nowrap;
    align-items: center;
    transition: transform 0.5s ease-in-out;
}

.grammy{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.grammy p{
    margin-left: 0.5em;
    align-items: center;
}

.line{
    display: flex;
    justify-content: start;
    border-right: 2px solid #F2F3F5;
    height: 4.5em;
    margin-right: 2.5em;
    margin-left: 2.5em;
    transition: opacity 0.5s ease-in-out;
}
.rightedge{
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin-right: 0.5em;
    transition: transform 0.5s ease-in-out;
    margin-left: 1em;
}
.location{
    width: 4em;
    height: 4em;
    margin: 0;
    margin-right: 0.5em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    top: 0.3em;
}

.contact{
    width: 4em;
    height: 4em;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.contact p{
    margin: 0; 
    white-space: nowrap;
}

.leftedge{
    transition: transform 0.5s ease-in-out;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
}




@media only screen and (max-width: 830px) {

    .footer{
        transform:scale(1, 1);
    }
    .footer p{
        margin-left: -2em;
        transform:scale(0.8, 0.8);
    }
    .grammy{
        margin-left: -0.1em;
    }
    .grammy p{
        margin-left: -0.6em;
    }
    .leftedge{
        width: 5%;
        transform:scale(0.8, 0.8);
        justify-content: start;
    }
    .rightedge{
        margin-right: -2em;
        transform:scale(0.8, 0.8);
    }
    .logo{
        transform:scale(1, 1);
    }
    .contact{
        align-items: flex-end;
        margin-left: -1em;
    }
 
    .location{
        margin-right: 0.2em;
    }
    .line{
        opacity: 0;
    }
}

@media only screen and (max-width: 510px) {
    .topspace{
        margin-top: 15em;
    }

    .footer{
        min-width: 300px;
        flex-direction: column;
    }
    .needtomove{
        margin-left: 2em;
    }
    .grammy{
        display: flex;
        justify-content: center;
        margin-left: -2.5em;
    }
    .leftedge{
        justify-content: center;
    }
    .rightedge{
        justify-content: center;
        margin-left: -3em;
        margin-top: -0.5em;
    }
    .rightedge p{
        justify-content: center;

    }
    .line{
        display: none;
    }
    .logo{
        display: none;
    }
    .location{
        align-items: start;
        margin-right: 0.5em;
    }
    .contact{
        align-items: center;
    }
}

