 .MuiDialogTitle-root{
    color: #F2F3F5 !important;
}


.MuiDialogContentText-root{
    font-family: Quicksand;
    font-weight: 600;
    color: #F2F3F5 !important;
}
.popup{
    background-color: rgba(32, 28, 36, 0.65);
}

  .MuiDialog-paper{
    background-color: #201C24 !important;

}
#popup .MuiButton-root{
    background-color: #201C24 !important;
    box-shadow: #00000080 0px 0px 5px 3px !important;
    color: #F2F3F5 !important;
    font-family: Quicksand !important;
    font-weight: 600 !important;
}

#popup .MuiButton-root:hover{
    background-color: #1d1920 !important;  
}
#popup .MuiTypography-root{
    text-wrap: wrap;
}